@tailwind base;
@tailwind components;
@tailwind utilities;

html {
        @apply bg-white ;
            scroll-behavior: smooth;
            font-size:16px !important;
        }

        body {
            font-size:16px !important;
        }

        #root{
            @apply bg-belsana-gray ;
        }

        .text-error{
            color:red !important;
        }

        .cards-grid {
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
            grid-auto-rows: auto;
        }

        .map-container {
            height: 600px;
        }

        .belsana-tooltip {
            @apply font-sans py-7 px-5 bg-white opacity-100 border border-gray-200 shadow-xl rounded-none text-base overflow-hidden !important;
            max-width: 320px;
            z-index: 1000;
        }

        .belsana-tooltip h2 {
            @apply text-3xl text-belsana mb-2 ;
        }

        .belsana-tooltip h3 {
            @apply text-2xl text-belsana mb-2 ;
        }

        .belsana-tooltip:before,.place-bottom:before {
            border-top:8px solid rgb(229, 231, 235) !important;
        }
        .place-bottom:before {
            border-bottom:8px solid rgb(229, 231, 235) !important;
        }

        .filterbar {
            z-index:1000 !important;
        }
        .selected-card{
            @apply shadow-2xl z-50 border border-gray-500;
        }

.search-field{
    font-size:16px !important;
}